import {AdminGuard, AuthGuard, GuestGuard} from "./guards";
import DashView from "../components/Dash.vue";
import LoginView from "../components/Login.vue";
import NotFoundView from "../components/NotFound.vue";
import DashboardView from "../components/views/Dashboard.vue";
// import ResetPasswordView from "./components/ResetPassword.vue";

/* load a component on demand [webpack syntax]*/
/* require.ensure( dependencies: String[], callback: function(require), errorCallback: function(error), chunkName: String) */

const AccountCreate = r => require.ensure([], () => r(require('../components/CreateAccount.vue')), 'user.order');
const ActivateAccount = r => require.ensure([], () => r(require('../components/ActivateAccount.vue')), 'user.order');
const UserCertAfterActivateAccount = r => require.ensure([], () => r(require('../components/UserCertAfterActivateAccount.vue')), 'user.order');
const AccountDelete = r => require.ensure([], () => r(require('../components/views/account/Delete.vue')), 'user.settings');
const AccountOverview = r => require.ensure([], () => r(require('../components/views/account/Overview.vue')), 'user');
const AccountPassword = r => require.ensure([], () => r(require('../components/views/account/Password.vue')), 'user');
const AccountSettings = r => require.ensure([], () => r(require('../components/views/account/Settings.vue')), 'user.settings');
const ConfirmAccountList = r => require.ensure([], () => r(require('../components/views/ConfirmAccountList.vue')), 'user.settings');
const ConfirmRequestForm = r => require.ensure([], () => r(require('../components/views/account/ConfirmRequestForm.vue')), 'user.settings');
const AccountUpdate = r => require.ensure([], () => r(require('../components/views/account/RequestForm.vue')), 'user.order');
const Agreement = r => require.ensure([], () => r(require('../components/views/account/Agreement.vue')), 'user');
const ConfirmResetMail = resolve => require(['../components/ConfirmResetMail.vue'], resolve);
const IdentificationView = r => require.ensure([], () => r(require('../components/views/account/Identification.vue')), 'user');
const RequestResetToken = resolve => require(['../components/RequestResetToken.vue'], resolve);
const ResetMail = r => require.ensure([], () => r(require('../components/views/account/ResetMail.vue')), 'user');
const ResetPassword = resolve => require(['../components/ResetPasword.vue'], resolve);
const ResourceList = r => require.ensure([], () => r(require('../components/views/resources/List.vue')), 'user.resource');
const ResourceConfirm = r => require.ensure([], () => r(require('../components/views/resources/ceo/List.vue')), 'user.resource');
const ResourceCreate = r => require.ensure([], () => r(require('../components/views/resources/Create.vue')), 'user.resource');
const ResourceOverview = r => require.ensure([], () => r(require('../components/views/resources/Overview.vue')), 'user.resource');
const GroupCert = r => require.ensure([], () => r(require('../components/views/resources/GroupCert.vue')), 'user.resource');
const ResourceUpdate = r => require.ensure([], () => r(require('../components/views/resources/Update.vue')), 'user.resource');
const FunctionalAccountPassword = r => require.ensure([], () => r(require('../components/views/resources/FunctionalAccountPassword.vue')), 'user.resource');
const UserCert = r => require.ensure([], () => r(require('../components/views/account/UserCert.vue')), 'user');

/* admin routes */
const AccountExchange = r => require.ensure([], () => r(require('../components/views/admin/account/Exchange.vue')), 'admin.ldap');
const AccountRequestForm = r => require.ensure([], () => r(require('../components/views/admin/account/RequestForm.vue')), 'admin.account');
const Accounts = r => require.ensure([], () => r(require('../components/views/admin/account/Accounts.vue')), 'admin.ldap');
const AdminAccountList = r => require.ensure([], () => r(require('../components/views/admin/account/AdminAccountList.vue')), 'admin.account');
const AdminAccountForm = r => require.ensure([], () => r(require('../components/views//admin/account/AdminAccountForm.vue')), 'admin.account');
const AdminView = r => require.ensure([], () => r(require('../components/views/admin/Admin.vue')), 'admin');
const EditorView = r => require.ensure([], () => r(require('../components/views/admin/Language.vue')), 'admin.ldap');
const FilesView = r => require.ensure([], () => r(require('../components/views/admin/Files.vue')), 'admin.ldap');
const RequestList = r => require.ensure([], () => r(require('../components/views/admin/RequestList.vue')), 'admin');
const ResourceMail = r => require.ensure([], () => r(require('../components/views/admin/resources/Mail.vue')), 'admin.resource');
const ResourceRequestForm = r => require.ensure([], () => r(require('../components/views/admin/resources/RequestForm.vue')), 'admin.resource');
const Resources = r => require.ensure([], () => r(require('../components/views/admin/resources/Resources.vue')), 'admin.ldap');

let routes = [
    {path: '/account/create/:law?', name: 'order', component: AccountCreate},
    {path: '/account/activate', name: 'account.activate', component: ActivateAccount},
    {path: '/account/user-cert-after-activate', name: 'account.userCertAfterActivate', component: UserCertAfterActivateAccount},
    {path: '/antrag', redirect: {name: 'order'}},
    {path: '/cgi-bin/login.cgi', redirect: {name: 'login'}}, // fix old 301 redirect issues
    {path: '/cgi-bin/index.cgi', redirect: {name: 'status'}}, // fix old 301 redirect issues
    {path: '/login', name: 'login', component: LoginView, beforeEnter: GuestGuard},
    {path: '/request-reset-token', component: RequestResetToken, beforeEnter: GuestGuard},
    {path: '/reset-mail/confirm/:uid/:token', component: ConfirmResetMail},
    {path: '/reset-password/:uid/:token', component: ResetPassword, beforeEnter: GuestGuard},
    {
        path       : '/',
        component  : DashView,
        beforeEnter: AuthGuard,
        children   : [
            {path: 'account', name: 'account.overview', component: AccountOverview},
            {path: 'account/agreements', name: 'account.agreements', component: Agreement},
            {path: 'account/identification', name: 'account.identification', component: IdentificationView},
            {path: 'account/extend/:law?', name: 'account.extend', component: AccountUpdate},
            {path: 'account/delete', name: 'account.delete', component: AccountDelete},
            {path: 'account/password', name: 'account.password', component: AccountPassword},
            {path: 'account/reset-mail', name: 'account.reset-mail', component: ResetMail},
            {path: 'account/user-cert', name: 'account.reset-mail', component: UserCert},
            {path: 'account/settings', name: 'account.settings', component: AccountSettings},
            {path: 'account/confirm/:order_number', name: 'account.confirm', component: ConfirmRequestForm},
            {path: 'accounts/confirm', name: 'accounts.confirm', component: ConfirmAccountList},
            {path: 'dashboard', name: 'status', alias: '', component: DashboardView},
            {path: 'resources/confirm', name: 'resource.confirm', component: ResourceConfirm},
            {path: 'resources/create/:type?', name: 'resource.create', component: ResourceCreate},
            {path: 'resources/extend/:type/:name', name: 'resource.extend', component: ResourceUpdate},
            {path: 'resources/create_group_cert/:type/:name', name: 'resource.create_group_cert', component: GroupCert},
            {path: 'resource/functional_account/:name', name: 'resource.password', component: FunctionalAccountPassword},
            {path: 'resources/overview', name: 'resource.overview', component: ResourceOverview},
            {path: 'resources/:type/list', name: 'resource.list', component: ResourceList},
            {
                path     : 'admin',
                component: AdminView,
                // beforeEnter: AdminGuard(),
                children : [
                    {
                        path       : 'account/exchange',
                        name       : 'admin.account-exchange',
                        component  : AccountExchange,
                        beforeEnter: AdminGuard(['ldap_admin']),
                    },
                    {
                        path       : 'account/request/:order_number',
                        name       : 'admin.account.request',
                        component  : AccountRequestForm,
                        beforeEnter: AdminGuard(['ldap_admin', 'user_admin']),
                    },
                    {
                        path       : 'accounts',
                        name       : 'admin.accounts',
                        component  : Accounts,
                        beforeEnter: AdminGuard(['ldap_admin', 'user_admin', 'pwd_admin', 'pwd_stud_admin', 'pwd_med_admin']),
                    },
                    {
                        path       : 'account_applications',
                        name       : 'admin.account_applications',
                        component  : AdminAccountList,
                        beforeEnter: AdminGuard(['ldap_admin', 'user_admin']),
                    },
                    {
                        path       : 'edit_account_applications/:order_number',
                        name       : 'admin.edit_account_applications',
                        component  : AdminAccountForm,
                        beforeEnter: AdminGuard(['ldap_admin', 'user_admin']),
                    },
                    {
                        path       : 'editor',
                        name       : 'editor',
                        component  : EditorView,
                        beforeEnter: AdminGuard(['language_admin']),
                    },
                    {
                        path       : 'files',
                        name       : 'files',
                        component  : FilesView,
                        beforeEnter: AdminGuard(['ldap_admin']),
                    },
                    {
                        path       : 'requests',
                        name       : 'admin.requests',
                        component  : RequestList,
                        beforeEnter: AdminGuard(['ldap_admin', 'user_admin', 'resource_admin', 'resource_manager']),
                    },
                    {
                        path       : 'resource/request/:type/:name',
                        name       : 'admin.resource.request',
                        component  : ResourceRequestForm,
                        beforeEnter: AdminGuard(['ldap_admin', 'resource_admin', 'resource_manager']),
                    },
                    {
                        path       : 'resource-mail',
                        name       : 'admin.resource-mail',
                        component  : ResourceMail,
                        beforeEnter: AdminGuard(['ldap_admin', 'resource_admin', 'resource_manager']),
                    },
                    {
                        path       : 'resources',
                        name       : 'admin.resources',
                        component  : Resources,
                        beforeEnter: AdminGuard(['ldap_admin', 'resource_admin', 'resource_manager']),
                    },
                ]
            }
        ]
    },
    {path: '*', component: NotFoundView}
];

export default routes;