<template>
    <div class="login-page">
        <div class="login-box">
            <div class="card">
                <div class="card-body login-card-body">
                    <img src="/img/logo.svg" class="logo" :alt="t('general.university_greifswald')" :title="t('general.university_greifswald')"/>
                    <h2 class="text-center" v-html='t("general.title")'> </h2>
                    <div class="row">
                        <div class="col-md-12 my-3 text-center">
                            {{ t('general.language_change') }}:
                            <a href="#" @click.prevent="changeLanguage(t('general.lang_opposite_key'))">
                                {{ t('general.lang_opposite') }}</a>
                        </div>
                    </div>

                    <form @submit.prevent="checkCredentials">
                        <loading-overlay></loading-overlay>
                        <alert type="warning" v-if="config('maintenance')">
                            {{ t('general.maintenance') }}
                        </alert>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" :placeholder="t('login.label_username')"
                                   autocomplete="off" v-model="uid" autofocus ref="uid" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-user"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" class="form-control" :placeholder="t('login.label_password')"
                                   v-model="password" required>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <alert type="danger" v-if="error">{{t(error)}}</alert>
                        <div class="row">
                            <div class="col-7 login-password-forgotten-link">
                                <router-link to="/request-reset-token">
                                    {{t('login.forgot_password')}}
                                </router-link>
                            </div>
                            <div class="col-5">
                                <button type="submit" class="btn btn-primary btn-block btn-flat my-2">
                                    {{t('login.label_button')}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.login-card-body -->
            </div>
            <global-errors v-if="withGlobalErrors"></global-errors>
        </div>
        <!-- /.login-box -->
    </div>
</template>

<script>
    import umsMixin from '../mixins/UMS';
    import componentResizeMixin from '../mixins/ComponentResize';
    import GlobalErrors from './GlobalErrors.vue';
    import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
    export default{
        data(){
            return {
                uid: '',
                password: '',
                error: false,
            }
        },
        components: {
            GlobalErrors,
            LoadingOverlay
        },
        computed: {
            language(){
                return this.t("general.lang_key") || 'de';
            }
        },
        mixins: [umsMixin, componentResizeMixin],
        methods: {
            checkCredentials(){
                let {uid, password, language} = this;
                this.$store.dispatch('account/login', {uid, password, language})
                    .then(response => {
                        this.error = false;
                        if (!response.data.active) {
                            return this.$router.push({name: 'account.password'});
                        }
                        let redirect = this.$route.query.redirect;
                        this.$router.push(redirect ? redirect : '/');
                    })
                    .catch(({response}) => {
                        this.error = 'general.unknown_error'; // default error
                        if (response.status === 401) this.error = response.data.message;
                        if (response.status === 406 && response.data.errors && response.data.errors.uid) {
                            this.error = response.data.errors.uid;
                        }
                        if (response.status >= 500) this.error = 'login.system_failure';
                        this.password = '';
                        this.$refs.uid.select();
                        this.$refs.uid.focus();
                    })
            },
            changeLanguage(language){
                this.$store.dispatch('language/change', {language});
            }
        }
    };
</script>
<style>
    .login-password-forgotten-link {
        padding-top: 1rem;
    }
</style>